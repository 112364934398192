import { Box } from "@theme-ui/components"
import React from "react"
import Nav from "./nav"
import MobileNav from "./mobileNav"

const Header = ({ theme, home = false }) => {
  return (
    <Box
      as="header"
      sx={{
        height: ["auto","auto","auto","100vh"],
      }}
    >
      <Box sx={{ display: ["none", "none", "block", "block"] }}>
        <Nav theme={theme} />
      </Box>
      <Box sx={{ display: ["block", "block", "none", "none"] }}>
        <MobileNav />
      </Box>
    </Box>
  )
}

export default Header
