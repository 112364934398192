import React, { useState, useEffect } from "react"
import { Box, Container, Flex } from "@theme-ui/components"
import { useMenu } from "../../hooks/useMenu"
import { getHomePath, getSearchPath } from "../../utils/path"
import { InboundLink, OutboundLink } from "./link"
import { MagicLink } from "../../utils/magicLink"
import LanguageSwitcher from "./languageSwitcher"
import { debounce } from "lodash"
import { LanguageSwitcherContext } from "../../context/languageSwitcherContext"
import { PageSectionContext } from "../../context/pageSectionContext"
import * as Scroll from "react-scroll"
import Logo from "../../images/logo.inline.svg"
import MenuBurger from "../../images/menu.inline.svg"
import Close from "../../images/close.inline.svg"
const LinkScroll = Scroll.Link

const MobileNav = ({scrollDirection}) => {
  const pageSections = React.useContext(PageSectionContext)
  const [show, setShow] = useState(false)
  const locale = React.useContext(LanguageSwitcherContext).activeLocale

  const menu = useMenu()

  menu.sort((a, b) => a.position - b.position)
  menu.map(menuItem => {
    menuItem.treeChildren.sort((a, b) => a.position - b.position)
    menuItem.treeChildren.map(menuItem => {
      if (menuItem.treeChildren.length > 0) {
        menuItem.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })

  return (
    <Box sx={{ position: "relative", width: "100%", height:"100%" }}>
      <Box sx={{
        height:"100%"
      }}> 
        <Container sx={{ paddingY: [3, 3],height:"100%" }}>
          <Flex
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height:"100%"
            }}
          >
            <Box sx={{ width: "60%" }}>
              <InboundLink
                to={getHomePath(locale)}
                sx={{
                  display: "inline-block",
                  width: "100%",
                  svg: {
                    maxWidth:"70px",
                    width: ["100%"],
                    height: "auto",
                    fill: pageSections ? "light" : "primary",
                  },
                }}
              >
                <Logo />
              </InboundLink>
            </Box>
            <Box
              onClick={() => setShow(!show)}
              sx={{
                svg: {
                  height: "24px",
                  stroke: pageSections ? "light" : "primary",
                },
              }}
            >
              <MenuBurger />
            </Box>
          </Flex>
        </Container>
      </Box>
      {show && (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "light",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: 1000,
            height: "auto",
            minHeight: "100vh",
            maxHeight: "100vh",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Container sx={{ backgroundColor: "light", paddingY: [3, 3] }}>
              <Flex
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "60%" }}>
                  <InboundLink
                    to={getHomePath(locale)}
                    sx={{
                      display: "inline-block",
                      width: "100%",
                      svg: {
                        width: ["100%"],
                        maxWidth:"70px",
                        height: "auto",
                        fill: "dark",
                      },
                    }}
                  >
                    <Logo />
                  </InboundLink>
                </Box>
                <Box
                  onClick={() => setShow(!show)}
                  sx={{
                    svg: {
                      height: "24px",
                    },
                  }}
                >
                  <Close color="#ffffff" />
                </Box>
              </Flex>
            </Container>
            <Flex
              sx={{
                flexDirection: "column",
                p: 2,
                margin: 0,
                alignItems: "start",
                listStyle: "none",
              }}
              as="ul"
            >
              {pageSections &&
                pageSections.map(item => (
                  <TextComponent
                    setShow={setShow}
                    item={item}
                    locale={locale}
                    key={item.id}
                  />
                ))}
              {console.log("menu", menu)}
              {!pageSections &&
                menu &&
                menu.map((item, index) => (
                  <TextComponent
                    item={item}
                    locale={locale}
                    key={item.id}
                    index={index}
                    pageSections={false}
                  />
                ))}
            </Flex>
          </Box>
          <Flex
            sx={{
              flexDirection: "row",
              padding: 3,
              margin: 0,
              listStyle: "none",
              alignItems: "center",
            }}
          >
            {menu &&
              menu.map((item, index) => (
                <TextComponent
                  item={item}
                  locale={locale}
                  key={item.id}
                  index={index}
                  pageSections={false}
                />
              ))}
          </Flex>
        </Box>
      )}
    </Box>
  )
}

const TextComponent = ({ item, locale, setShow, pageSections = true }) => {
  const componentTheme = "light"
  const light = componentTheme === "light" ? "light" : "dark"

  return (
    <Box
      as="li"
      key={item.id}
      sx={{
        minHeight: "55px",
        position: "relative",
        fontWeight: "normal",
        color: light,
        "&:last-child": {
          ul: {
            right: 0,
          },
        },
        "& > a, & > div": {
          color: "light",
          paddingX: 3,
          paddingY: [3],
          display: "block",
        },
      }}
      onClick={() => pageSections && setShow(false)}
    >
      <Flex
        sx={{
          display: "flex!important",
          cursor: "default",
          alignItems: "center",
          a: {
            fontSize: [4,6],
            color: "dark",
          },
        }}
      >
        {pageSections && (
          <LinkScroll
            onClick={() => setShow(false)}
            spy={false}
            smooth={true}
            duration={500}
            to={`${item.id}`}
          >
            {item.title}
          </LinkScroll>
        )}

        {!pageSections && item.link ? (
          <MagicLink
            target="_self"
            item={item.link}
            locale={locale}
          ></MagicLink>
        ) : (
          <Box sx={{ cursor: "default" }}>{item.anchor}</Box>
        )}
      </Flex>
    </Box>
  )
}

const TextComponentLink = ({ item, locale, setShow }) => {
  const componentTheme = "dark"
  const light = componentTheme === "light" ? "light" : "dark"

  return (
    <Box
      as="li"
      key={item.id}
      sx={{
        minHeight: "55px",
        position: "relative",
        fontWeight: "normal",
        color: light,
        "&:last-child": {
          ul: {
            right: 0,
          },
        },
        "& > a, & > div": {
          color: "dark",
          paddingX: 3,
          paddingY: [3],
          display: "block",
        },
      }}
      onClick={() => setShow(false)}
    >
      <Flex
        sx={{
          display: "flex!important",
          cursor: "default",
          alignItems: "center",
        }}
      >
        {item.link && <MagicLink item={item.link} locale={locale}></MagicLink>}
      </Flex>
    </Box>
  )
}

export default MobileNav
