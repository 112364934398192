import React, { useState, useEffect } from "react"
import { Box, Container, Flex } from "@theme-ui/components"
import { useMenu } from "../../hooks/useMenu"
import { getHomePath, getSearchPath } from "../../utils/path"
import { InboundLink, OutboundLink } from "./link"
import { MagicLink } from "../../utils/magicLink"
import LanguageSwitcher from "./languageSwitcher"
import { debounce } from "lodash"
import { LanguageSwitcherContext } from "../../context/languageSwitcherContext"
import { PageSectionContext } from "../../context/pageSectionContext"
import * as Scroll from "react-scroll"
import Logo from "../../images/logo.inline.svg"
const LinkScroll = Scroll.Link

const Nav = ({ scrollDirection }) => {
  const pageSections = React.useContext(PageSectionContext)
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const menu = useMenu()

  menu.sort((a, b) => a.position - b.position)
  menu.map(menuItem => {
    menuItem.treeChildren.sort((a, b) => a.position - b.position)
    menuItem.treeChildren.map(menuItem => {
      if (menuItem.treeChildren.length > 0) {
        menuItem.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })

  return (
    <Box
      as="nav"
      sx={{
        position:"fixed",
        height: "100%",
        minWidth:"250px",
        borderRight:"1px solid",
        borderColor:"borderLightGrey"

      }}
    >
      <Container
        sx={{
          paddingX: [3, 4],
          borderBottom: scrollDirection !== "up" && "1px solid",
          borderColor: "light",
          py: [0, 0],
          height: "100%",
        }}
      >
        <Flex
          sx={{
            height: "100%",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Box sx={{ pt: [6], pb: [7] }}>
            <Logo />
          </Box>
          <Flex
            sx={{
              flexDirection: "row",
              padding: 0,
              margin: 0,
              listStyle: "none",
              display: "flex",
              flexDirection: "column",
            }}
            as="ul"
          >
            {pageSections &&
              pageSections.map(item => (
                <Box
                  as="li"
                  sx={{
                    position: "relative",
                    pb: 3,
                    a: {
                      fontSize: [1],
                      lineHeight: "15px",
                      fontWeight: "500",
                      textDecoration: "none",
                      color: "dark",
                      "&:hover": {
                        color:"primary",
                        textDecoration: "underline",
                      },
                    },
                  }}
                >
                  <LinkScroll
                    spy={false}
                    smooth={true}
                    duration={500}
                    to={`${item.id}`}
                    href="#"
                  >
                    {item.title}
                  </LinkScroll>
                </Box>
              ))}
            {!pageSections &&
              menu &&
              menu.map((item, index) => (
                <TextComponent
                  item={item}
                  locale={locale}
                  key={item.id}
                  index={index}
                />
              ))}
          </Flex>
          <Flex
            sx={{
              flexDirection: "row",
              padding: 3,
              margin: 0,
              listStyle: "none",
              alignItems: "center",
            }}
          >
            {menu &&
              menu.map((item, index) => (
                <TextComponent
                  item={item}
                  locale={locale}
                  key={item.id}
                  index={index}
                />
              ))}
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

const TextComponent = ({ item, locale, index }) => {
  const [show, setShow] = useState(false)

  const debouncedHandleMouseEnterMenu = debounce(() => {
    setShow(true)
  }, 200)

  const handlOnMouseLeaveMenu = () => {
    setShow(false)
    debouncedHandleMouseEnterMenu.cancel()
  }

  return (
    <Box
      as="li"
      key={item.id}
      sx={{
        position: "relative",
        pb: 3,
        a: {
          fontSize: [1],
          lineHeight: "15px",
          fontWeight: "500",
          textDecoration: "none",
          color: "dark",
          "&:hover": {
            color:"primary",
            textDecoration: "underline",
          },
        },
      }}
      onMouseEnter={() => debouncedHandleMouseEnterMenu()}
      onMouseLeave={() => handlOnMouseLeaveMenu()}
    >
      {item.link ? (
        <MagicLink item={item.link} locale={locale}></MagicLink>
      ) : (
        <Box sx={{ cursor: "default" }}>{item.anchor}</Box>
      )}
      {item.treeChildren.length > 0 && show && (
        <Box
          as="ul"
          sx={{
            listStyle: "none",
            padding: 3,
            margin: 0,
            backgroundColor: "lightBackground",
            position: "absolute",
            top: 40,
            width: "max-content",
            boxShadow:
              "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
            borderRadius: "sm",
          }}
        >
          {item.treeChildren.map(item =>
            item.anchor ? (
              <Box as="li" key={item.id}>
                {item.link ? (
                  <MagicLink item={item.link} locale={locale} />
                ) : (
                  <Box sx={{ cursor: "default" }}>{item.anchor}</Box>
                )}
              </Box>
            ) : null
          )}
        </Box>
      )}
    </Box>
  )
}

export default Nav
