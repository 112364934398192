import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"

export const useMenu = () => {
  const menu = useStaticQuery(graphql`
    query MenuQuery {
      allDatoCmsMenu(
        filter: { root: { eq: true }, anchor: { ne: null } }
        sort: { fields: position, order: ASC }
      ) {
        nodes {
          id
          locale
          root
          position
          anchor
          link {
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
          }
          treeChildren {
            id
            locale
            root
            anchor
            position
            link {
              ... on DatoCmsInternalLink {
                ...InternalLinkDetails
              }
              ... on DatoCmsExternalLink {
                ...ExternalLinkDetails
              }
            }
            treeChildren {
              id
              locale
              root
              position
              anchor
              link {
                ... on DatoCmsInternalLink {
                  ...InternalLinkDetails
                }
                ... on DatoCmsExternalLink {
                  ...ExternalLinkDetails
                }
              }
            }
          }
        }
      }
    }
  `)

  const locale = React.useContext(LanguageSwitcherContext).activeLocale

  const i18nMenu = menu.allDatoCmsMenu.nodes.filter(
    link => link.locale === locale
  )

  return i18nMenu
}
